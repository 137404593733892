import { useEffect, useState } from "react";
import {  IconX } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { lenText } from "../helper";
import {typeDateHelprer,toBottomx} from "../helper.js"


import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

function BookCar() {
  const today = new Date().toISOString().split("T")[0];

  const apiUrl = process.env.REACT_APP_API_URL;

  let navigate = useNavigate();
  // booking car

  const [carType, setCarType] = useState("");
  const [pickUp, setPickUp] = useState("");
  const [pickTime, setPickTime] = useState(new Date());
  const [listMarkCar, setlistMarkCar] = useState([]);
  const [cities, setCities] = useState([]);
  const [type, setType] = useState("car");


  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [validDate, setvalidDate] = useState(false);


  const [brandFilter,setBrandFilter] = useState([])



  const setDateCarDef = (e) => {
    setDateRange(e);
    setvalidDate(false)
  };

  // model

  useEffect(() => {
    setCarType("");
    setPickUp("");
  }, []);

  useEffect(() => {
    axios.get(`${apiUrl}/marks`).then((res) => {
      if (res.status === 200) {
        setlistMarkCar(res.data);
      }
    });

  }, []);

  useEffect(() => {

    
    axios
      .get(`${apiUrl}/vehicles`)
      .then((res) => {
        if (res.status === 200) {


          const brands = Array.from(new Set(res.data.map(el => JSON.stringify({ ...el.brand, type: el.type })))).map(JSON.parse);

          setlistMarkCar(brands)


          const all = res.data.map(el=>{
            return el.agency.location.name
          })

          setCities([...new Set(all)])

          

        }
      })
      .catch((err) => {});





  }, []);

  useEffect(()=>{

    const listMark = listMarkCar.filter((el)=>{
      return el.type  === type
    })

    setBrandFilter(listMark)


  },[type,listMarkCar])

  const FnCarType = (e) => {
    console.log(e.target.value);
    setCarType(e.target.value);
  };

  const FnpickUp = (e) => {
    console.log(e.target.value);
    setPickUp(e.target.value);
  };

  const FnpickTime = (e) => {
    console.log(e.target.value);
    setPickTime(e.target.value);
  };

  const typeMsg = () => {
    return type === "car"
      ? "Choisissez un type de voiture !"
      : "Choisissez un type de moto !";
  };

  const FnSearch = (e) => {
    e.preventDefault();

    if (carType !== "" && pickUp !== "" && startDate !== null && endDate !== null) {
      navigate(
        `/models/${type}/${carType}/${pickUp}/${typeDateHelprer(startDate)}/${typeDateHelprer(endDate)}`
      );

      console.log("ok");
    } else if (carType === "") {
      toast.error(typeMsg, {
        style: {
          padding: "10px",
          fontSize: "16px",
          background: "#FFF5F5",
        },
      });
    } else if (pickUp === "") {
      toast.error("agency !", {
        style: {
          padding: "10px",
          fontSize: "16px",
          background: "#FFF5F5",
        },
      });
    } else if (startDate === null || endDate === null) {
      toast.error(" Date de réservation !", {
        style: {
          padding: "10px",
          fontSize: "16px",
          background: "#FFF5F5",
        },
      });
    }
  };

  return (
    <>
      <section id="booking-section" className="book-section">
        <div className="container">
          <div className="book-content">
            <div className="book-content__box">
              <div className="btns_tayp">
                <button
                  className={type == "car" ? "active" : ""}
                  onClick={() => {
                    setType("car");
                    setCarType("");
                    toBottomx();
                  }}
                >
                  <i className="material-symbols-outlined">directions_car</i>{" "}
                  voiture
                </button>
                <button
                  className={type == "moto" ? "active" : ""}
                  onClick={() => {
                    setType("moto");
                    setCarType("");
                    toBottomx();
                    
                  }}
                >
                  <i className="material-symbols-outlined">two_wheeler</i>moto
                </button>
              </div>
              {type == "car" ? (
                <h2>Réserver une voiture</h2>
              ) : (
                <h2>Réserver une moto</h2>
              )}

              <p className="error-message">
                All fields required! <IconX width={20} height={20} />
              </p>

              <p className="booking-done">
                Check your email to confirm an order.{" "}
                <IconX width={20} height={20} onClick={() => {}} />
              </p>

              <form className="box-form">
                <div className="box-form__car-type">
                  {type == "car" ? (
                    <label>
                      <span className="material-symbols-outlined">
                        directions_car
                      </span>{" "}
                      &nbsp; Choisissez un type de voiture <b>*</b>
                    </label>
                  ) : (
                    <label>
                      <span className="material-symbols-outlined">
                        directions_car
                      </span>{" "}
                      &nbsp; Choisissez un type de moto <b>*</b>
                    </label>
                  )}

                  <div className="div_input">
                    <i className="material-symbols-outlined">arrow_drop_down</i>
                    <select value={carType} onChange={FnCarType}>
                      <option value="">
                        {type === "car"
                          ? lenText("Sélectionnez le type de voiture", 30)
                          : lenText("Sélectionnez le type de moto", 30)}
                      </option>
                      {
                        brandFilter.map((el, i) => {
                          return (
                            <option key={i} value={el.name}>
                              {el.name.toLowerCase()}
                            </option>
                          );
                        })
                      }
                    </select>
                  </div>
                </div>

                <div className="box-form__car-type">
                  <label>
                    <span className="material-symbols-outlined">
                      location_on
                    </span>{" "}
                    &nbsp; Ville <b>*</b>
                  </label>
                  <div className="div_input">
                    <i className="material-symbols-outlined">arrow_drop_down</i>
                    <select value={pickUp} onChange={FnpickUp}>
                      <option value="">
                        {" "}
                        {lenText("Sélectionnez le lieu de prise en", 30)}
                      </option>
                      {cities.map((el, i) => {
                        return (
                          <option value={el} key={i}>
                            {el.toLowerCase()}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                {/* <div className="box-form__car-time">
                  <label htmlFor="picktime">
                    <IconCalendarEvent className="input-icon" /> &nbsp; Date de
                    réservation <b>*</b>
                  </label>
                  <input
                    id="picktime"
                    value={pickTime}
                    onChange={FnpickTime}
                    type="date"
                    min={today}
                  ></input>
                </div> */}

                <div className="box-form__car-time">
                  <label htmlFor="picktime">
                    <span className="material-symbols-outlined">event</span>{" "}
                    &nbsp; Date de réservation <b>*</b>
                  </label>
                  {/* <DatePicker
                
                
                    showIcon
                    selected={pickTime}
                    onChange={(date) => setPickTime(date)}
                    minDate={new Date()}
                  /> */}
                  <DatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={setDateCarDef}
                    withPortal
                    placeholderText="date de réservation"
                    minDate={new Date()}
                  />
                </div>

                <button
                  className="btn_recherche_"
                  onClick={FnSearch}
                  type="submit"
                >
                  recherche
                </button>
              </form>
            </div>
          </div>
        </div>
        <Toaster position="top-center" reverseOrder={false} />
      </section>
    </>
  );
}

export default BookCar;
