


export function lenText(txt, n) {
  return txt.split("").slice(0, n).join("") + "..";
}

export const typeDateHelprer = (da) => {
  const inputDate = new Date(da);

  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  const day = String(inputDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export const  toBottomx = () =>{

    const scrolll = document.documentElement;

    if (scrolll.scrollTop < scrolll.clientHeight) {
        window.scrollTo(0, scrolll.clientHeight - 188);
    }

}
