import { IconMail, IconMailOpened, IconPhone } from "@tabler/icons-react";
import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import { IconLocation } from "@tabler/icons-react";
import { useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

function Contact() {
  const email = useRef();
  const name = useRef();
  const msg = useRef();

  const [error_email, setError_email] = useState(false);
  const [error_name, setError_name] = useState(false);
  const [error_msg, setError_msg] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  function sendForm(e) {
    e.preventDefault();

    const v_email = email.current.value.trim();
    const v_name = name.current.value.trim();
    const v_msg = msg.current.value.trim();

    let send = true;

    if (!emailRegex.test(v_email)) {
      send = false;
      setError_email(true);
    }

    if (v_name === "") {
      send = false;
      setError_name(true);
    }

    if (v_msg === "") {
      send = false;
      setError_msg(true);
    }

    if (send) {
      axios
        .post("https://jsonplaceholder.typicode.com/posts", {
          name: v_name,
          email: v_email,
          message: v_msg,
        })
        .then((res) => {
          console.log(res.status);
          if (res.status === 201) {
            toast.success("Votre message a été reçu !", {
              style: {
                fontSize: "16px",
              },
            });

            setTimeout(() => {
              email.current.value = "";
              msg.current.value = "";
              name.current.value = "";
            }, 2000);
          }
        }).catch(error=>{
          toast.error("Message non reçu .",{
            style: {
              fontSize: "16px",
            },
          })
          //console.log(error)
        });
    }

    //console.log(v_email, v_name, v_msg);
  }

  return (
    <>
      <section className="contact-page">
        <HeroPages name="Contact" />
        <div className="container">
          <div className="contact-div">
            <div className="contact-div__text">
              <h2>Besoin d'informations complémentaires ?</h2>
              <p>
              Nous sommes ravis de pouvoir vous aider. N'hésitez pas à nous contacter pour toute question, demande d'information ou assistance. Nous sommes là pour vous offrir le meilleur service possible.
              </p>
              <a href="tel:212775779660">
                <IconPhone /> &nbsp; +212 775779660
              </a>
              <a href="tel:212525998870">
                <IconPhone /> &nbsp; +212 525998870
              </a>
              <a href="mailto:support@ikcars.ma">
                <IconMail /> &nbsp; support@ikcars.ma
              </a>
              <a href="#">
                <IconLocation />
                &nbsp; marrakech
              </a>
            </div>
            <div className="contact-div__form">
              <form onSubmit={sendForm}>
                <label>
                  le nom
                  <b>*</b>
                </label>
                <input
                  onChange={() => setError_name(false)}
                  className={error_name ? "error_input" : null}
                  ref={name}
                  type="text"
                  placeholder='le nom'
                ></input>

                <label>
                  Email <b>*</b>
                </label>
                <input
                  onChange={() => setError_email(false)}
                  className={error_email ? "error_input" : null}
                  ref={email}
                  type="email"
                  placeholder="email@example.com"
                ></input>

                <label>
                Message <b>*</b>
                </label>
                <textarea
                  onChange={() => setError_msg(false)}
                  className={error_msg ? "error_input" : null}
                  ref={msg}
                  placeholder="Écrivez ici.."
                ></textarea>

                <button type="submit">
                  <IconMailOpened />
                  &nbsp; Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Réservez Votre Voiture en Nous Contactant </h2>
              <span>
                <IconPhone width={40} height={40} />
                <h3>+212 775779660</h3>
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
}

export default Contact;
