import React,{useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie"


function Profile() {

  const [ok,setOk] = useState(false)

  const navigate = useNavigate();

  useEffect(()=>{

    if(Cookies.get('isLogin') && Cookies.get('isLogin') == "false") {

      navigate("/login");

    }else {
      setOk(true)
    }
    
  },[])

  return ok && (
    <div className='profile_page'>Profile</div>
  )
}

export default Profile