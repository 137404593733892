import { Link } from "react-router-dom";
//import BgShape from "../images/hero/hero-bg.png";
import BgShape from "../images/hero/hero-bg.png";
import HeroCar from "../images/hero/main-car.png";
import { useEffect, useState } from "react";
import { IconChevronRight, IconCircleCheck } from "@tabler/icons-react";

function Hero() {
  const [goUp, setGoUp] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: (0, 0), behavior: "smooth" });
  };

  const bookBtn = () => {
    document
      .querySelector("#booking-section")
      .scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.pageYOffset > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);
  return (
    <>
      <section id="home" className="hero-section">
        <div className="container">
          <img className="bg-shape" src={BgShape} alt="bg-shape" />
          <div className="hero-content">
            <div className="hero-content__text">
              <h4>Planifiez Votre Voyage dès Maintenant</h4>
              <h1>
                Économisez En Grand avec <span>Notre</span> Service de Location
                de Voiture
                {/* Save <span>big</span> with our car rental */}
              </h1>
              <p>
                Préparez-vous à une expérience inoubliable en planifiant votre
                voyage avec nous. Chez Ikcars, nous vous offrons bien plus
                qu'une simple location de voiture. Découvrez le véhicule de vos
                rêves à des tarifs imbattables, avec des avantages tels que des
                kilomètres illimités, des options de prise en charge flexibles,
                et bien plus encore.
              </p>
              <div className="hero-content__text__btns">
                <Link
                  onClick={bookBtn}
                  className="hero-content__text__btns__book-ride"
                  to="/models"
                >
                  Réservez votre trajet &nbsp;
                  <i className="material-symbols-outlined">arrow_forward</i>
                </Link>
                {/* <Link className="hero-content__text__btns__learn-more" to="/models">
                Modèles de véhicules &nbsp; <IconChevronRight />
                </Link> */}
              </div>
            </div>

            {/* img */}
            <img
              src={HeroCar}
              alt="car-img"
              className="hero-content__car-img"
            />
          </div>
        </div>

        {/* page up */}
        <div
          onClick={scrollToTop}
          className={`scroll-up ${goUp ? "show-scroll" : ""}`}
        >
          <i className="material-symbols-outlined">arrow_upward</i>
        </div>
      </section>
    </>
  );
}

export default Hero;
