
import { useState } from "react";
import { Link } from "react-router-dom";

function HeroPages({ name }) {

  const [nm, setNm] = useState("");

  

  useState(()=>{


    switch (name) {
      case "About":
        setNm("À propos")
        break;
      case "car":
          setNm("véhicules")
          break;
      case "Testimonials":
          setNm("Témoignages")
          break;
      case "Contact":
          setNm("Contact")
          break;
      
    }
  },[])


  return (
    <>
      <section className="hero-pages">
        <div className="hero-pages__overlay"></div>
        <div className="container">
          <div className="hero-pages__text">
            <h3>{ nm }</h3>
            <p>
              <Link to="/">Acceuil</Link> / { nm }
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default HeroPages;
