//import SelectCar from "../images/plan/icon1.png";
import SelectCar from "../images/icon-info/f-2.png";
import Contact from "../images/icon-info/f-3.png";
import Drive from "../images/icon-info/f-1.png";

function PlanTrip() {
  return (
    <>
      <section className="plan-section">
        <div className="container">
          <div className="plan-container">
            <div className="plan-container__title">
              <h3>Planifiez Votre Aventure Dès Maintenant</h3>
              <h2>Location de Voiture Rapide et Facile</h2>
            </div>

            <div className="plan-container__boxes">
              <div className="plan-container__boxes__box">
                <img src={SelectCar} alt="icon_img" />

                <h3>Choisissez Votre Voiture</h3>
                <p>
                  Nous vous offrons un éventail étendu de véhicules pour
                  répondre à tous vos besoins de conduite. Trouvez la voiture
                  parfaite qui s'adapte à vos exigences et assure une expérience
                  de conduite exceptionnelle.
                </p>
              </div>

              <div className="plan-container__boxes__box">
                <img src={Contact} alt="icon_img" />
                <h3>Contactez notre Service Client</h3>
                <p>
                  Nos opérateurs compétents et amicaux sont toujours prêts à
                  répondre à toutes vos questions ou préoccupations.
                  Contactez-nous pour une assistance personnalisée et des
                  conseils avisés.
                </p>
              </div>

              <div className="plan-container__boxes__box">
                <img src={Drive} alt="icon_img" />
                <h3>Partons Ensemble</h3>
                <p>
                  Que vous preniez la route, nous avons ce qu'il vous faut avec
                  notre vaste sélection de voitures. Choisissez parmi notre
                  flotte diversifiée pour une conduite confortable et adaptée à
                  vos besoins.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PlanTrip;
