import React, { useEffect, useRef } from "react";
import "../dist/login.css";
import logo from "../images/logo/logo.png";
import bgXll from "../images/login/bgLogin.jpg";
import { Link } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import axios  from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie"

const apiUrl = process.env.REACT_APP_API_URL;

function Login() {
  const userName = useRef();
  const Password = useRef();
  const navigate = useNavigate();

  function valid(user, pass) {

    let itsOk = true;

    if (user === "") {

      itsOk = false;

      toast.error("This didn't work. user",{style:{fontSize:14}})

    }

    if (pass === "") {

      itsOk = false;

      toast.error("This didn't work. password",{style:{fontSize:14}})

    }

    return itsOk;
  }

  function handleForm() {

    let email = userName.current.value.trim();
    let password = Password.current.value.trim();

    if (valid(email, password)) {

      console.log(userName.current.value.trim(), Password.current.value.trim());

      axios.post(`${apiUrl}/auth/login`,{email,password}).then(res=>{

        
        if (res.status === 200) {


          Cookies.set('isLogin',true)
          Cookies.set('token',res.data.token)
          Cookies.set('user',JSON.stringify(res.data.user))


          navigate("/");
          
        }

      }).catch(error=>{
        
        if(error.message === 'Request failed with status code 422') toast.error("This didn't work. user",{style:{fontSize:14}})
        
      })

    }

  }

  useEffect(()=>{

    if(Cookies.get('isLogin') && Cookies.get('isLogin') == "true") {
      navigate("/");
    }
    
  },[])

  useEffect(() => {
    
    userName.current.focus();
  }, []);

  return (
    <div
      className="contianer_login"
      style={{ backgroundImage: `url(${bgXll})` }}
    >
      <div className="boxs">
        <div className="boxLogin">
          <div className="logo">
            <img src={logo} alt="-" width="100%" />
          </div>
          <h2>Accueillir</h2>
          <div className="inputs">
            <input
              type="text"
              name="username"
              id="username"
              placeholder="Adresse e-mail ou numéro de tél."
              ref={userName}
            />
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Mot de passe"
              ref={Password}
            />
            <input type="button" value="Se connecter" onClick={handleForm} />
          </div>
          <span className="line"></span>
          <Link to="/profile">Créer nouveau compte</Link>
        </div>
      </div>

      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
}

export default Login;
