import { IconQuote } from "@tabler/icons-react";
import Img2 from "../images/testimonials/user_1.png";
import Img3 from "../images/testimonials/user_2.png";

function Testimonials() {
  return (
    <>
      <section className="testimonials-section">
        <div className="container">
          <div className="testimonials-content">
            <div className="testimonials-content__title">
              <h4>Évalué par les utilisateurs</h4>
              <h2>Témoignages des clients</h2>
              <p>
              Découvrez l'impact positif que nous avons eu sur nos clients en lisant leurs témoignages. Nos clients ont vécu notre service et nos résultats, et ils sont impatients de partager leurs expériences positives avec vous
              </p>
            </div>

            <div className="all-testimonials">
              <div className="all-testimonials__box">
                <span className="quotes-icon">
                  <IconQuote width={60} height={60} />
                </span>
                <p>
                  "Nous avons loué une voiture sur ce site web et avons eu une expérience incroyable ! La réservation était facile et les tarifs de location étaient très abordables
. "
                </p>
                <div className="all-testimonials__box__name">
                  <div className="all-testimonials__box__name__profile">
                    <img src={Img2} alt="user_img" />
                    <span>
                      <h4>Karim</h4>
                      <p>kenitra</p>
                    </span>
                  </div>
                </div>
              </div>

              <div className="all-testimonials__box box-2">
                <span className="quotes-icon">
                  <IconQuote width={60} height={60} />
                </span>
                <p>
                  "La voiture était en excellent état et a rendu notre voyage encore meilleur. Je recommande vivement ce site de location de voiture!"
                </p>
                <div className="all-testimonials__box__name">
                  <div className="all-testimonials__box__name__profile">
                    <img src={Img3} alt="user_img" />
                    <span>
                      <h4>Ayoub</h4>
                      <p>casablanca</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonials;
