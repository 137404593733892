import Footer from "../components/Footer";
import { Link, useParams } from "react-router-dom";
import { IconCar, IconPhone, IconStar } from "@tabler/icons-react";
import { useEffect, useMemo, useState } from "react";
import ModelForm from "../components/ModelForm";
import imgDf from "../images/book-car/df.png";
import axios from "axios";
import Chip from '@mui/material/Chip';
import "../dist/filter.css";

// filter ----------

import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import Box from "@mui/material/Box";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";

import LocationOnIcon from '@mui/icons-material/LocationOn';



import { useRef } from "react";

// filter ------------

function Models() {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [dataApi, setDataApi] = useState([]);

  const [cars, setCars] = useState([]);
  const [modelF, setModelF] = useState(false);
  const [carId, setCarId] = useState();
  const [nameCar, setNameCar] = useState();
  const [cityCar, setCityCar] = useState();

  const [price,setPrice] = useState(0)
  const [discount,setDiscount] = useState({})


  const [msgLoading, setMsgLoading] = useState("loading ...");

  // state use param --------------

  const { type, city, start, end, tp } = useParams();

  const [paramType, setParamType] = useState(undefined);
  const [paramCity, setParamCity] = useState(undefined);
  const [paramStart, setParamStart] = useState(undefined);
  const [paramEnd, setParamEnd] = useState(undefined);
  const [paramTp, setParamTp] = useState(tp);

  const [priceMax, setPriceMax] = useState(2000);
  const [priceMin, setPriceMin] = useState(0);

  // state use param --------------

  const [cities, setCities] = useState([]);
  const [listMarkCar, setlistMarkCar] = useState([]);

  // api brands filter ------

  const [brandsList, setBrandList] = useState([]);

  const [brandFilter, setBrandFilter] = useState([]);

  const [listAgency, setListAgency] = useState([]);

  const [listAgencyFilterByCity, setListAgencyFilterByCity] = useState([]);

  const [nameAgency, setNameAgency] = useState("ALL");

  const [listAllCity, setListAllCity] = useState([]);

  // ----------------

  // data json filter -------------

  const [boxAvtiveById, setBoxAvtiveById] = useState(null);

  console.log(type, city, start, end, tp);
  console.log(paramType, paramTp);

  const boxFilter = useRef();

  const handleChangeTp = (event) => {
    setParamTp(event.target.value);
    setParamType("ALL");
  };

  const handleNameAgency = (event) => {
    setNameAgency(event.target.value);

    //console.log(event.target.value);
  };

  const handleChangeCity = (event) => {
    setParamCity(event.target.value);
    setNameAgency("ALL");
  };

  const handleChangeBrand = (event) => {
    setParamType(event.target.value);
  };

  const handleChangePriceMax = (event) => {
    event.target.value.match(/^\d*(\.\d+)?$/)
      ? setPriceMax(event.target.value)
      : setPriceMax(3000);
  };

  const handleChangePriceMin = (event) => {
    event.target.value.match(/^\d*(\.\d+)?$/)
      ? setPriceMin(event.target.value)
      : setPriceMin(1);
  };

  const showFilter = (el) => {
    el.target.classList.toggle("btn_box_show"); //btn_box_show
    console.log(boxFilter.current);
    boxFilter.current.classList.toggle("filter_box_show");
  };

  useEffect(() => {
    axios
      .get(`${apiUrl}/cities`)
      .then((res) => {
        if (res.status === 200) {
          setCities(res.data);
        }
      })
      .catch((err) => {
        setCities([]);
      });
  }, []);

  useEffect(() => {
    setParamType(type);
    setParamCity(city);
    setParamTp(tp);
  }, [tp, end, start, city, type]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/vehicles`)
      .then((res) => {
        if (res.status === 200) {
          setDataApi(res.data);

          const brands = Array.from(
            new Set(
              res.data.map((el) =>
                JSON.stringify({ ...el.brand, type: el.type })
              )
            )
          ).map(JSON.parse);

          const lAgency = res.data.map((el) => el.agency); //listAgency

          const uniqueArray = Array.from(
            new Set(lAgency.map((item) => item.id)),
            (id) => lAgency.find((item) => item.id === id)
          );

          setBrandList([{ name: "ALL" }, ...brands]);
        }
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    const filterBrand = brandsList.filter((el) => {
      return el.type === paramTp;
    });

    setBrandFilter([{ name: "ALL" }, ...filterBrand]);
  }, [paramTp, brandsList]);

  useEffect(() => {
    let newData = dataApi.filter((item) => {
      const typeMatch =
        paramType && paramType !== "ALL" ? item.brand.name === paramType : true;

      const tpMatch = paramTp ? item.type === paramTp : true;

      const cityMatch =
        paramCity && paramCity !== "ALL"
          ? item.agency.location.name === paramCity
          : true;

      const minMatch =
        item.price >= Number(priceMin) && item.price <= Number(priceMax);

      const agenceMatch =
        nameAgency && nameAgency !== "ALL"
          ? item.agency.name === nameAgency
          : true;

      return typeMatch && tpMatch && cityMatch && minMatch && agenceMatch;
    });

    if (newData.length === 0) {
      setMsgLoading("Aucun résultat trouvé ...");
    }

    setCars(newData);

    const lAgency = dataApi.map((el) => el.agency); //listAgency

    const uniqueArray = Array.from(
      new Set(lAgency.map((item) => item.id)),
      (id) => lAgency.find((item) => item.id === id)
    );

    let x = [{ name: "ALL", location: { name: "ALL" } }, ...uniqueArray];

    setListAgency(x);

    const allCity = x.map((el) => {
      return el.location.name;
    });

    setListAllCity([...new Set(allCity)]);

    let filter = listAgency.filter((el) => {
      const agencyMatch =
        paramCity && paramCity !== "ALL"
          ? el.location.name === paramCity
          : true;

      return agencyMatch;
    });

    setListAgencyFilterByCity([
      { name: "ALL", location: { name: "ALL" } },
      ...filter,
    ]);

    
  }, [paramCity, dataApi, nameAgency, paramType, paramTp, priceMax, priceMin]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);

    if (
      document.querySelector(".mobile-navbar").classList.contains("open-nav")
    ) {
      document.querySelector(".mobile-navbar").classList.remove("open-nav");
    }
  }, []);

  const modelHidden = (e) => {
    console.log(e.target.className === "ModelForm");
    if (e.target.className === "ModelForm") {
      setModelF(false);
    }
  };

  const sendData = (e) => {

    
    setModelF(true);
    setCarId(e.target.dataset.id);
    setNameCar(e.target.dataset.name);
    setPrice(e.target.dataset.price);
    
    setDiscount(e.target.dataset.discount)

    
  };

  const handleTp = (el) => {
    setParamTp(el.target.value);
  };

  return (
    <>
      {modelF && (
        <ModelForm
          modelHidden={modelHidden}
          idCar={carId}
          nameCar={nameCar}
          price={price}
          discount={discount}
        />
      )}
      <section className="models-section">
        <section className="hero-pages">
          <div className="hero-pages__overlay"></div>
          <div className="container">
            <div className="hero-pages__text">
              <h3>
                {paramTp === "car" ? "voiture" : paramTp} {!paramTp && "rrr"}
              </h3>
              <p>
                <Link to="/">Acceuil</Link> /{" "}
                {paramTp === "car" ? "voiture" : paramTp}
              </p>
            </div>
          </div>
        </section>

        <div className="container">
          <div className="btn_div_filter">
            <button className="btnFilter" onClick={showFilter}>
              filter
              <i className="material-symbols-outlined">filter_list</i>
            </button>
          </div>
          <div className="filter_box" ref={boxFilter}>
            {/* <div className="el">
              <FormControl
                className="el_box"
                sx={{ m: 1, width: "100%", margin: "0" }}
                size="small"
              >
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Type de véhicule
                </FormLabel>

                <Select
                  sx={{ fontSize: "14px", height: "40px" }}
                  labelId="emo-controlled-open-select-label"
                  id="demo-select-small"
                  value={paramTp}
                  onChange={handleChangeTp}
                  className="input_filter_el"
                >
                  <MenuItem sx={{ fontSize: "12px" }} value="car">
                    voiture
                  </MenuItem>
                  <MenuItem sx={{ fontSize: "12px" }} value="moto">
                    Moto
                  </MenuItem>

                </Select>
              </FormControl>
            </div> */}

            <div className="el">
              <FormControl
                className="el_box"
                sx={{ m: 1, width: "100%", margin: "0" }}
                size="small"
              >
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Ville
                </FormLabel>

                <Select
                  sx={{ fontSize: "14px", textTransform: "lowercase" }}
                  labelId="emo-controlled-open-select-label"
                  id="demo-select-small"
                  value={paramCity ? paramCity : "ALL"}
                  onChange={handleChangeCity}
                  className="input_filter"
                >
                  {listAllCity.map((el, i) => {
                    return (
                      <MenuItem
                        key={i}
                        sx={{ fontSize: "12px", textTransform: "lowercase" }}
                        value={el}
                      >
                        {el === "ALL" ? "toute" : el}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>

            {/* el 2 */}

            <div className="el">
              <FormControl
                className="el_box"
                sx={{ m: 1, width: "100%", margin: "0" }}
                size="small"
              >
                <FormLabel id="demo-row-radio-buttons-group-label">
                  agence
                </FormLabel>

                <Select
                  sx={{ fontSize: "14px", height: "40px" }}
                  labelId="emo-controlled-open-select-label"
                  id="demo-select-small"
                  value={nameAgency}
                  onChange={handleNameAgency}
                  className="input_filter_el"
                >
                  {[...new Map(listAgencyFilterByCity.map(item => [item.name, item])).values()].map((el, i) => {


                    console.log(el)
                    return el.name.location === paramCity || 1 == 1 ? (
                      <MenuItem
                        key={i}
                        sx={{ fontSize: "12px" }}
                        value={el.name}
                      >
                        {el.name === "ALL" ? "toute" : el.name}
                      </MenuItem>
                    ) : null;
                  })}
                </Select>
              </FormControl>
            </div>

            {/* 3 */}

            <div className="el">
              <FormControl
                className="el_box"
                sx={{ m: 1, width: "100%", margin: "0" }}
                size="small"
              >
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Type De {paramTp === "car" ? "Voiture" : "Moto"}
                </FormLabel>

                <Select
                  sx={{ fontSize: "14px", textTransform: "lowercase" }}
                  labelId="emo-controlled-open-select-label"
                  id="demo-select-small"
                  value={paramType ? paramType : "ALL"}
                  onChange={handleChangeBrand}
                  className="input_filter"
                >
                  {brandFilter.map((el, i) => {
                    return (
                      <MenuItem
                        key={i}
                        sx={{ fontSize: "12px" }}
                        value={el.name}
                      >
                        {el.name === "ALL" ? "Toute" : el.name.toLowerCase()}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>

            <div className="el">
              <Box className="el_box" sx={{ m: 1, width: "100%", margin: "0" }}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Prix
                </FormLabel>
                <Box
                  component="form"
                  sx={{
                    minWidth: 120,
                    display: "flex",
                    gap: "10px",
                    height: "40px",
                    alignItems: "center",
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment position="end">Dhs</InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    placeholder="Min"
                    sx={{ fontSize: "12px", height: "40px" }}
                    onChange={handleChangePriceMin}
                    value={priceMin}
                  />
                  <span className="between-span"></span>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment position="end">Dhs</InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    placeholder="Max"
                    sx={{ fontSize: "12px", height: "40px" }}
                    onChange={handleChangePriceMax}
                    value={priceMax}
                  />
                </Box>
              </Box>
            </div>
          </div>

          <div className="models-div">
            {cars.length > 0 ? (
              cars.map((e, i) => {
                
                return (
                  <div className="models-div__box" key={i}>  
                    <div
                      className={
                        boxAvtiveById == e.id ? "info info_show" : "info" 
                      }
                    >
                      <button
                        className="btnClose"
                        onClick={() => {
                          setBoxAvtiveById(null);
                        }}
                      >
                        <i className="material-symbols-outlined">close</i>
                      </button>

                      <table className="tableInfo">
                        <tbody>
                          <tr>
                            <th>-</th>
                            <th>-</th>
                          </tr>
                          <tr>
                            <td>brand</td>
                            <td>{e.brand.name}</td>
                          </tr>
                          <tr>
                            <td>name</td>
                            <td>{e.name}</td>
                          </tr>
                          <tr>
                            <td>details</td>
                            <td>{e.details.length > 25 ? '-' : e.details}</td>
                          </tr>
                          <tr>
                            <td>model</td>
                            <td>{e.model}</td>
                          </tr>
                          <tr>
                            <td>fuel</td>
                            <td>{e.fuel}</td>
                          </tr>
                          <tr>
                            <td>transmission</td>
                            <td>{e.transmission}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="models-div__box__img">
                      {/* <span className="city">{e.agency.location.name}</span> */}
                      

                      <Chip icon={<LocationOnIcon />} style={{
                          position: "absolute",
                          right: "0",
                          top: "0",
                          fontSize:"12px",
                          backgroundColor:"#e2e2e4",
                          borderRadius:"5px",
                          userSelect:"none"
                        }} label={e.agency.location.name.toLowerCase()} />



                      <img src={e.img[0] ? e.img[0] : imgDf} alt="car_img" />
                      <div className="models-div__box__descr">
                        <div className="models-div__box__descr__name-price">
                          <div className="models-div__box__descr__name-price__name">
                            <p>{e.name}</p>
                            <span className="icon_star">
                              <i className="material-symbols-outlined">
                                star_half
                              </i>
                              5
                            </span>
                          </div>
                          <div className="models-div__box__descr__name-price__price">
                            <h4>{`${Number(e.price).toFixed(1)} DH`}</h4>
                            <p>par jour</p>
                          </div>
                        </div>
                        <div className="models-div__box__descr__name-price__details">
                          <span>
                            <i className="material-symbols-outlined">
                               {e.type === "moto" ? 'two_wheeler' : 'directions_car'}
                            </i>{" "}
                            {e.mark}
                          </span>
                          <span style={{ textAlign: "right" }}>
                            <i className="material-symbols-outlined">
                              door_back
                            </i>{" "}
                            { e.type === "moto" ? "-" : e.doors}
                          </span>
                          <span>
                            <i className="material-symbols-outlined">
                              auto_transmission
                            </i>{" "}
                            {e.transmission}
                          </span>
                          <span style={{ textAlign: "right" }}>
                            <i className="material-symbols-outlined">
                              local_gas_station
                            </i>{" "}
                            {e.fuel}
                          </span>
                        </div>
                        <div className="models-div__box__descr__name-price__btn">
                          <div className="btnss">
                            <button
                              data-id={e.id}
                              data-name={e.name}
                              data-city={e.agency.location.name}
                              onClick={() => {
                                setBoxAvtiveById(e.id);
                              }}
                            >
                              détails
                            </button>

                            {e.is_booked ? (
                              <button
                              
                                disabled
                                style={{
                                  background: "#e3e3e3",
                                  color: "#606060",
                                }}
                              >
                                Pas Disponible
                              </button>
                            ) : (
                              <button
                                data-id={e.id}
                                data-name={e.name}
                
                                data-price={Math.floor(e.price)}
                                data-discount={JSON.stringify(e.vehiclePrices[0])}
                                onClick={sendData}
                              >
                                Réservez
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <span className="loading">{msgLoading}</span>
            )}

            {/* <div className="models-div__box">
              <div className="models-div__box__img">
                <img src={CarImg2} alt="car_img" />
                <div className="models-div__box__descr">
                  <div className="models-div__box__descr__name-price">
                    <div className="models-div__box__descr__name-price__name">
                      <p>Golf 6</p>
                      <span>
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                      </span>
                    </div>
                    <div className="models-div__box__descr__name-price__price">
                      <h4>$37</h4>
                      <p>per day</p>
                    </div>
                  </div>
                  <div className="models-div__box__descr__name-price__details">
                    <span>
                      <IconCar /> &nbsp; VW
                    </span>
                    <span style={{ textAlign: "right" }}>
                      4/5 &nbsp; <IconCar />
                    </span>
                    <span>
                      <IconCar /> &nbsp; Manual
                    </span>
                    <span style={{ textAlign: "right" }}>
                      Diesel &nbsp; <IconCar />
                    </span>
                  </div>
                  <div className="models-div__box__descr__name-price__btn">
                    <Link onClick={() => window.scrollTo(0, 0)} to="/">
                      Book Ride
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="models-div__box">
              <div className="models-div__box__img">
                <img src={CarImg3} alt="car_img" />
                <div className="models-div__box__descr">
                  <div className="models-div__box__descr__name-price">
                    <div className="models-div__box__descr__name-price__name">
                      <p>Toyota</p>
                      <span>
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                      </span>
                    </div>
                    <div className="models-div__box__descr__name-price__price">
                      <h4>$30</h4>
                      <p>per day</p>
                    </div>
                  </div>
                  <div className="models-div__box__descr__name-price__details">
                    <span>
                      <IconCar /> &nbsp; Camry
                    </span>
                    <span style={{ textAlign: "right" }}>
                      4/5 &nbsp; <IconCar />
                    </span>
                    <span>
                      <IconCar /> &nbsp; Manual
                    </span>
                    <span style={{ textAlign: "right" }}>
                      Diesel &nbsp; <IconCar />
                    </span>
                  </div>
                  <div className="models-div__box__descr__name-price__btn">
                    <Link onClick={() => window.scrollTo(0, 0)} to="/">
                      Book Ride
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="models-div__box">
              <div className="models-div__box__img">
                <img src={CarImg4} alt="car_img" />
                <div className="models-div__box__descr">
                  <div className="models-div__box__descr__name-price">
                    <div className="models-div__box__descr__name-price__name">
                      <p>BMW 320</p>
                      <span>
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                      </span>
                    </div>
                    <div className="models-div__box__descr__name-price__price">
                      <h4>$35</h4>
                      <p>per day</p>
                    </div>
                  </div>
                  <div className="models-div__box__descr__name-price__details">
                    <span>
                      <IconCar /> &nbsp; ModernLine
                    </span>
                    <span style={{ textAlign: "right" }}>
                      4/5 &nbsp; <IconCar />
                    </span>
                    <span>
                      <IconCar /> &nbsp; Manual
                    </span>
                    <span style={{ textAlign: "right" }}>
                      Diesel &nbsp; <IconCar />
                    </span>
                  </div>
                  <div className="models-div__box__descr__name-price__btn">
                    <Link onClick={() => window.scrollTo(0, 0)} to="/">
                      Book Ride
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="models-div__box">
              <div className="models-div__box__img">
                <img src={CarImg5} alt="car_img" />
                <div className="models-div__box__descr">
                  <div className="models-div__box__descr__name-price">
                    <div className="models-div__box__descr__name-price__name">
                      <p>Mercedes</p>
                      <span>
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                      </span>
                    </div>
                    <div className="models-div__box__descr__name-price__price">
                      <h4>$50</h4>
                      <p>per day</p>
                    </div>
                  </div>
                  <div className="models-div__box__descr__name-price__details">
                    <span>
                      <IconCar /> &nbsp; Benz GLK
                    </span>
                    <span style={{ textAlign: "right" }}>
                      4/5 &nbsp; <IconCar />
                    </span>
                    <span>
                      <IconCar /> &nbsp; Manual
                    </span>
                    <span style={{ textAlign: "right" }}>
                      Diesel &nbsp; <IconCar />
                    </span>
                  </div>
                  <div className="models-div__box__descr__name-price__btn">
                    <Link onClick={() => window.scrollTo(0, 0)} to="/">
                      Book Ride
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="models-div__box">
              <div className="models-div__box__img">
                <img src={CarImg6} alt="car_img" />
                <div className="models-div__box__descr">
                  <div className="models-div__box__descr__name-price">
                    <div className="models-div__box__descr__name-price__name">
                      <p>VW Passat</p>
                      <span>
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                      </span>
                    </div>
                    <div className="models-div__box__descr__name-price__price">
                      <h4>$25</h4>
                      <p>per day</p>
                    </div>
                  </div>
                  <div className="models-div__box__descr__name-price__details">
                    <span>
                      <IconCar /> &nbsp; CC
                    </span>
                    <span style={{ textAlign: "right" }}>
                      4/5 &nbsp; <IconCar />
                    </span>
                    <span>
                      <IconCar /> &nbsp; Manual
                    </span>
                    <span style={{ textAlign: "right" }}>
                      Diesel &nbsp; <IconCar />
                    </span>
                  </div>
                  <div className="models-div__box__descr__name-price__btn">
                    <Link onClick={() => window.scrollTo(0, 0)} to="/">
                      Book Ride
                    </Link>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Réservez Votre Voiture en Nous Contactant </h2>
              <span>
                <IconPhone width={40} height={40} />
                <h3>+212 775779660</h3>
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default Models;
