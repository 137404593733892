import { Link } from "react-router-dom";
import Logo from "../images/logo/logo.png";
import { useState } from "react";
import { IconMenu2, IconX } from "@tabler/icons-react";

function Navbar() {
  const [nav, setNav] = useState(false);

  const openNav = () => {
    setNav(!nav);
  };

  return (
    <>
      <nav>
        {/* mobile */}
        <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
          <div onClick={openNav} className="mobile-navbar__close">
            <IconX width={30} height={30} />
          </div>
          <ul className="mobile-navbar__links">
            <li>
              <Link onClick={openNav} to="/">
              Acceuil
              </Link>
            </li>
            <li>
              <Link onClick={openNav} to="/about">
              À propos
              </Link>
            </li>
            <li>
              <Link onClick={openNav} to="/models/car">
              voiture
              </Link>
            </li>
            <li>
              {" "}
              <Link className="testi-link" to="/models/moto">
                moto
              </Link>
            </li>
            {/* <li>
              <Link onClick={openNav} to="/team">
                Our Team
              </Link>
            </li> */}
            
            <li>
              <Link onClick={openNav} to="/contact">
              Contact
              </Link>
            </li>

            <li>
              <Link onClick={openNav} to="/register">
              Registre
              </Link>
            </li>
            <li>
              <Link onClick={openNav} to="/login">
              Se connecter
              </Link>
            </li>
          </ul>
        </div>

        {/* desktop */}

        <div className="navbar">
          <div className="navbar__img">
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              <img src={Logo} alt="logo-img" />
            </Link>
          </div>
          <ul className="navbar__links">
            <li>
              <Link className="home-link" to="/">
              Acceuil
              </Link>
            </li>
            <li>
              {" "}
              <Link className="about-link" to="/about">
              À propos
              </Link>
            </li>
            <li>
              {" "}
              <Link className="models-link" to="/models/car">
              voiture
              </Link>
            </li>
            <li>
              {" "}
              <Link className="testi-link" to="/models/moto">
                moto
              </Link>
            </li>
            {/* <li>
              {" "}
              <Link className="team-link" to="/team">
                Our Team
              </Link>
            </li> */}
            
            <li>
              {" "}
              <Link className="contact-link" to="/contact">
              Contact
              </Link>
            </li>
          </ul>
          <div className="navbar__buttons">
            <Link className="navbar__buttons__sign-in" to="/login">
            Se connecter
            </Link>
            <Link className="navbar__buttons__register" to="/register">
            Registre
            </Link>
            {/* <Link className="navbar__buttons__register" to="/models">
            Réservez  maintenant
            </Link> */}
          </div>

          {/* mobile */}
          <div className="mobile-hamb" onClick={openNav}>
            <IconMenu2 width={30} height={30} />
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
