import { useEffect, useState } from "react";
import CarBox from "./CarBox";
import ModelForm from "../components/ModelForm";
import axios from "axios";

function PickCar() {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [active, setActive] = useState("FirstCar");
  const [colorBtn, setColorBtn] = useState("btn0");
  const [modelF, setModelF] = useState(false);
  const [CarData, setCarData] = useState([]);
  const [carId, setCarId] = useState("");
  const [nameCar, setNameCar] = useState("");
  const [cityCar, setCityCar] = useState("");
  const [price,setPrice] = useState(0)
  const [discount,setDiscount] = useState({})


  const btnID = (id) => {
    setColorBtn(colorBtn === id ? "" : id);
  };

  const coloringButton = (id) => {
    return colorBtn === id ? "colored-button" : "";
  };

  const modelShow = () => {
    setModelF(true);
  };
  const modelHidden = (e) => {
    console.log(e.target.className === "ModelForm");
    if (e.target.className === "ModelForm") {
      setModelF(false);
    }
  };

  useEffect(() => {
    axios.get(`${apiUrl}/vehicles`).then((res) => {
      console.log(res.data);
      if (res.status === 200) {
        if (res.data.length > 6) {
          setCarData(res.data.slice(0, 6));
        } else {
          setCarData(res.data);
        }

        setNameCar(res.data[0].name);
        setCarId(res.data[0].id);
        setCityCar(res.data[0].agency.location.name);
        setPrice(Math.floor(res.data[0].price))
        setDiscount(JSON.stringify(res.data[0].vehiclePrices[0]))
        
      }
    });
  }, []);

  

  const listNumber = [
    "FirstCar",
    "SecondCar",
    "ThirdCar",
    "FourthCar",
    "FifthCar",
    "SixthCar",
  ];

  const btns = CarData.map((e, i) => {
    return (
      <button
        key={i}
        
        className={`${coloringButton(`btn${i}`)}`}
        onClick={() => {
          setActive(listNumber[i]);
          btnID(`btn${i}`);
          setCarId(e.id);
          setNameCar(e.name);
          setCityCar(e.agency.location.name);
          setPrice(Math.floor(e.price))
          setDiscount(JSON.stringify(e.vehiclePrices[0]))
          //console.log(JSON.stringify(e.vehiclePrices[0]))
        }}
      >
        {e.name}
      </button>
    );
  });

  return (
    <>
      {modelF && (
        <ModelForm
          modelHidden={modelHidden}
          idCar={carId}
          nameCar={nameCar}
          price={price}
          discount={discount}
        />
      )}
      <section className="pick-section">
        <div className="container">
          <div className="pick-container">
            <div className="pick-container__title">
              <h3>Modèles de Véhicules</h3>
              <h2>Découvrez Notre Flotte de Location</h2>
              <p>
                Explorez notre gamme complète de modèles de véhicules
                exceptionnels, prêts à vous accompagner lors de votre prochaine
                aventure ou voyage d'affaires
              </p>
            </div>
            <div className="pick-container__car-content">
              {/* pick car */}
              <div className="pick-box">{CarData.length > 0 ? btns : null}</div>

              {CarData.length > 0 ? (
                CarData.map((e, i) => {
                  return (
                    active === listNumber[i] && (
                      <CarBox data={e} key={i} modelShow={modelShow} />
                    )
                  );
                })
              ) : (
                <span className="loading">loding ...</span>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PickCar;
