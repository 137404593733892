
function LoadingCom() {
 
  return(
    
    <div className="loading_div">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

  );
}

export default LoadingCom;
