import React, { useEffect, useMemo, useRef, useState } from 'react'

import './../dist/ModelForm.css'
import toast, { Toaster } from 'react-hot-toast'
import axios from 'axios'

import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import { motion } from 'framer-motion'

function ModelForm({ modelHidden, idCar, nameCar, price, discount, modelF }) {
    const states = new Array(
        'Agadir',
        'Al Hoceima',
        'Azilal',
        'Beni Mellal',
        'Ben Slimane',
        'Boulemane',
        'Casablanca',
        'Chaouen',
        'El Jadida',
        'El Kelaa des Sraghna',
        'Er Rachidia',
        'Essaouira',
        'Fes',
        'Figuig',
        'Guelmim',
        'Ifrane',
        'Kenitra',
        'Khemisset',
        'Khenifra',
        'Khouribga',
        'Laayoune',
        'Larache',
        'Marrakech',
        'Meknes',
        'Nador',
        'Ouarzazate',
        'Oujda',
        'Rabat-Sale',
        'Safi',
        'Settat',
        'Sidi Kacem',
        'Tangier',
        'Tan-Tan',
        'Taounate',
        'Taroudannt',
        'Tata',
        'Taza',
        'Tetouan',
        'Tiznit',
    )

    // form info user

    const [infoUser, setInfoUser] = useState(JSON.parse(window.localStorage.getItem('user')))

    //---

    const [fullName, setFullName] = useState(infoUser.name)
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState(infoUser.phone)
    const [email, setEmail] = useState(infoUser.email)
    const [city, setCity] = useState('')
    const [CarForm, setCarForm] = useState(nameCar)
    const [DateCar, setDateCar] = useState()
    const [endDateCar, setEndDateCar] = useState()
    const [zipCode, setZipCode] = useState('')
    const [address, setAddress] = useState('')

    const [totalDays, setTotalDays] = useState(1)
    const [totalAmount, setTotalAmount] = useState(0)
    const [afterDiscount, SetafterDiscount] = useState(null)

    // range date

    const [dateRange, setDateRange] = useState([null, null])
    const [startDate, endDate] = dateRange

    // valide form

    const [validfullName, setvalidFullName] = useState(false)
    const [validphone, setvalidPhone] = useState(false)
    const [validemail, setvalidEmail] = useState(false)
    const [validlastName, setvalidlastName] = useState(false)
    const [validcity, setvalidCity] = useState(false)
    const [validZipCode, setvalidZipCode] = useState(false)
    const [validAddress, setvalidAddress] = useState(false)
    const [validDate, setvalidDate] = useState(false)

    const apiUrl = process.env.REACT_APP_API_URL

    //const calculation = getCsrf()

    // loding send

    const [loadingSend, sztLoadingSend] = useState(false)

    const ModelForm = useRef()

    useEffect(() => {
        setTotalAmount(price)
    }, [])

    // functions
    const setNameDef = (e) => {
        setFullName(e.target.value)
        setvalidFullName(false)
    }
    const setLastNameDef = (e) => {
        setLastName(e.target.value)
        setvalidlastName(false)
    }
    const setPhoneDef = (e) => {
        setPhone(e.target.value)
        setvalidPhone(false)
    }
    const setEmailDef = (e) => {
        setEmail(e.target.value)
        setvalidEmail(false)
    }
    const setCityDef = (e) => {
        setCity(e.target.value)
        setvalidCity(false)
    }
    const setCarFormDef = (e) => {
        setCarForm(e.target.value)
    }

    const setDateCarDef = (e) => {
        setDateRange(e)
        setvalidDate(false)
        const [start, end] = e

        //console.log(JSON.parse(discount))

        const discountApi = JSON.parse(discount)

        if (start && end) {
            const date1 = new Date(start)
            const date2 = new Date(end)
            const timeDifference = date2.getTime() - date1.getTime()

            const t = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

            setTotalDays(t === 0 ? 1 : t)

            if (t >= discountApi.from && t <= discountApi.to) {
                //console.log(discountApi)
                SetafterDiscount(Math.floor(discountApi.price))
                setTotalAmount(Math.floor(discountApi.price) * t)
            } else if (t === 0) {
                setTotalAmount(price * 1)
                SetafterDiscount(null)
            } else {
                setTotalAmount(price * t)
                SetafterDiscount(null)
            }
        }
    }

    const setZipCodeDef = (e) => {
        setZipCode(e.target.value)
        setvalidZipCode(false)
    }

    const setAddressDef = (e) => {
        setAddress(e.target.value)
        setvalidAddress(false)
    }

    const handleSubmit = (e) => {
        console.log(DateCar)

        e.preventDefault()

        if (fullName === '' || fullName.length < 3) {
            setvalidFullName(true)
        }
        if (lastName === '' || lastName.length < 3) {
            setvalidlastName(true)
        }
        if (phone === '' || !/^(06|05|04|08|07)[0-9]{8}$/.test(phone)) {
            setvalidPhone(true)
        }
        if (email === '' || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
            setvalidEmail(true)
        }
        if (city === '') {
            setvalidCity(true)
        }

        if (!/^[0-9]{4,6}$/.test(zipCode)) {
            setvalidZipCode(true)
        }

        if (address === '') {
            setvalidAddress(true)
        }

        if (startDate === null || endDate === null) {
            setvalidDate(true)
        }

        if (
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email) &&
            /^(06|05|04|08|07)[0-9]{8}$/.test(phone) &&
            fullName.length > 3 &&
            lastName.length > 3 &&
            city !== '' &&
            address !== '' &&
            startDate !== null &&
            endDate !== null
        ) {
            sztLoadingSend(true)

            const config = {
                headers: { 'content-type': 'application/json' },
                withCredentials: true,
            }

            axios
                .post(
                    `${apiUrl}/rent-logs`,
                    {
                        vehicle_id: idCar,
                        firstname: fullName,
                        lastname: lastName,
                        phone_number: phone,
                        email: email,
                        city: city,
                        pick_time: startDate,
                        drop_time: endDate,
                        zip_code: zipCode,
                        address: address,
                    },
                    config,
                )
                .then((res) => {
                    if (res.status === 201) {
                        toast.success('Votre réservation a été complétée avec succès !', {
                            style: {
                                padding: '16px',
                            },
                            duration: 2000,
                        })

                        setTimeout(() => {
                            ModelForm.current.click()
                        }, 2500)
                    }

                    sztLoadingSend(false)
                })
                .catch((er) => {
                    if (er.message === 'Network Error') {
                        toast.error('Aucune réservation effectuée!', {
                            style: {
                                fontSize: '16px',
                            },
                        })
                    } else {
                        if (er.response.status === 422) {
                            Object.values(er.response.data.errors).forEach((e) => {
                                toast.error(e, {
                                    style: {
                                        fontSize: '16px',
                                    },
                                })
                            })
                        }
                    }

                    sztLoadingSend(false)
                })
        }
    }

    function getCsrf() {
        return new Date().getTime()
    }

    return (
        <div className="ModelForm" onClick={modelHidden} ref={ModelForm}>
            <motion.div
                animate={{
                    opacity: [0, 1],
                    translateX: [-60, 30, 0],
                }}
                transition={{ type: 'spring', stiffness: 40 }}
                //transition={ {duration:0.8,ease:[.47,.47,.17,.68]} }

                className="formDiv">
                <h2>Réserver une voiture</h2>
                <form className="form_box" onSubmit={handleSubmit}>
                    <div className="box-form__car-time">
                        <label htmlFor="picktime">
                            {' '}
                            <i className="material-symbols-outlined">person</i> prénom
                        </label>
                        <input
                            value={fullName}
                            onChange={setNameDef}
                            type="text"
                            placeholder="prénom"></input>
                        {validfullName ? <span>Ce champ est obligatoire</span> : null}
                    </div>
                    <div className="box-form__car-time">
                        <label htmlFor="picktime">
                            {' '}
                            <i className="material-symbols-outlined">person</i> Nom
                        </label>
                        <input
                            value={lastName}
                            onChange={setLastNameDef}
                            type="text"
                            placeholder="Nom"></input>
                        {validlastName ? <span>Ce champ est obligatoire</span> : null}
                    </div>
                    <div className="box-form__car-time">
                        <label htmlFor="picktime">
                            <i className="material-symbols-outlined">call</i>téléphone
                        </label>
                        <input
                            value={phone}
                            onChange={setPhoneDef}
                            type="text"
                            placeholder="0600000000"></input>
                        {validphone ? <span>Ce champ est obligatoire</span> : null}
                    </div>
                    <div className="box-form__car-time">
                        <label htmlFor="picktime">
                            <i className="material-symbols-outlined">email</i>email
                        </label>
                        <input
                            value={email}
                            onChange={setEmailDef}
                            type="text"
                            placeholder="exemple@email.com"></input>
                        {validemail ? <span>Ce champ est obligatoire</span> : null}
                    </div>
                    <div className="box-form__car-time">
                        <label htmlFor="picktime">
                            <i className="material-symbols-outlined">home</i>ville
                        </label>
                        <select name="carlist" form="carform" onChange={setCityDef}>
                            <option value="">Choisissez votre ville</option>
                            {states.map((el, i) => {
                                return (
                                    <option key={i} value={el}>
                                        {el}
                                    </option>
                                )
                            })}
                        </select>
                        {validcity ? <span>Ce champ est obligatoire</span> : null}
                    </div>

                    <div className="box-form__car-time">
                        <label htmlFor="picktime">
                            <i className="material-symbols-outlined">location_on</i>address
                        </label>
                        <input
                            value={address}
                            onChange={setAddressDef}
                            type="text"
                            placeholder="Address"></input>
                        {validAddress ? <span>Ce champ est obligatoire</span> : null}
                    </div>

                    <div className="box-form__car-time">
                        <label htmlFor="picktime">
                            {' '}
                            <i className="material-symbols-outlined">local_post_office</i> code
                            postal
                        </label>
                        <input
                            value={zipCode}
                            onChange={setZipCodeDef}
                            type="text"
                            placeholder="code postal"></input>
                        {validZipCode ? <span>Ce champ est obligatoire</span> : null}
                    </div>
                    <div className="box-form__car-type">
                        <label>
                            <i className="material-symbols-outlined">directions_car</i>vehicule
                        </label>
                        <input
                            value={CarForm}
                            onChange={setCarFormDef}
                            type="text"
                            readOnly></input>
                    </div>
                    {/* <div className="box-form__car-time">
            <label htmlFor="picktime">
              <i className="material-symbols-outlined">event</i>date de
              réservation
            </label>
            <input
              value={DateCar}
              onChange={setDateCarDef}
              type="datetime-local"
              min={today}
            ></input>
          </div> */}
                    {/* <div className="box-form__car-time">
            <label htmlFor="picktime">
              <i className="material-symbols-outlined">event</i>date de
              réservation
            </label>
            <input
              value={endDateCar}
              onChange={setEndDateCarDef}
              type="datetime-local"
              min={DateCar}
            ></input>
          </div> */}
                    <div className="box-form__car-time date_picker_div">
                        <label htmlFor="picktime">
                            <i className="material-symbols-outlined">event</i>date de réservation
                        </label>
                        <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={setDateCarDef}
                            withPortal
                            placeholderText="date de réservation"
                            minDate={new Date()}
                        />
                        {validDate ? <span>Ce champ est obligatoire</span> : null}
                    </div>

                    <div className="lineForm">
                        <span>details</span>
                    </div>

                    <div className="box-form__car-time box-form__car-time_two_input">
                        <div className="boxx">
                            <label htmlFor="picktime">Prix ​​par jour</label>
                            <span className="dhSpan">dh</span>
                            <input
                                className="el_total"
                                disabled
                                onChange={() => {
                                    console.log('')
                                }}
                                value={price ? price : 0}
                                type="text"></input>
                        </div>
                        <div className="boxx">
                            <label htmlFor="picktime">après remise</label>
                            <span className="dhSpan">dh</span>
                            <input
                                className="el_total"
                                disabled
                                onChange={() => {
                                    console.log('')
                                }}
                                value={afterDiscount ? afterDiscount : '-'}
                                type="text"></input>
                        </div>
                    </div>

                    <div className="box-form__car-time box-form__car-time_two_input">
                        <div className="boxx">
                            <label htmlFor="picktime">jours</label>
                            <input
                                className="el_total"
                                disabled
                                onChange={() => {
                                    console.log('')
                                }}
                                value={totalDays}
                                type="text"></input>
                        </div>
                        <div className="boxx">
                            <label htmlFor="picktime">total</label>
                            <span className="dhSpan">dh</span>
                            <input
                                className="el_total"
                                disabled
                                onChange={() => {
                                    console.log('hi')
                                }}
                                value={totalAmount}
                                type="text"></input>
                        </div>
                    </div>

                    <div className="lineForm lineForm_2"></div>

                    <div className="box-form__car-time btn_submit">
                        <input
                            value={loadingSend ? 'Envoi en cours ...' : 'Reserver maintenant'}
                            type="submit"></input>
                    </div>
                </form>
            </motion.div>
            <Toaster position="top-right" reverseOrder={false} />
        </div>
    )
}

export default ModelForm
